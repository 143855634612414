.cont-breadcrumb {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  align-items: center;
}
.cont-breadcrumb .breadcrumb .ant-breadcrumb-link {
  font-size: 16px;
  color: #333;
}
.cont-breadcrumb .breadcrumb .ant-breadcrumb-link a {
  color: #1890ff;
}
