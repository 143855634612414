.goods-storage,
.goods-classify {
  padding: 15px;
  background-color: #fff;
}
.goods-storage .btn-box,
.goods-classify .btn-box {
  padding: 15px 0;
}
.goods-storage .search-box,
.goods-classify .search-box {
  background-color: #f7f8fa;
  padding: 15px;
}
.goods-storage-set .page-header {
  padding-top: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #f0f0f0;
}
.goods-storage-set .sku .sku-set-box {
  border: 1px solid #f0f0f0;
  padding: 15px;
}
.goods-storage-set .sku .sku-set-box .sku-list-item .head {
  background-color: #f8f8f8;
  padding: 8px 15px;
  position: relative;
}
.goods-storage-set .sku .sku-set-box .sku-list-item .head .anticon.close {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: none;
}
.goods-storage-set .sku .sku-set-box .sku-list-item .head .anticon.close:hover {
  color: rgba(0, 0, 0, 0.4);
}
.goods-storage-set .sku .sku-set-box .sku-list-item .head:hover .anticon.close {
  display: block;
}
.goods-storage-set .sku .sku-set-box .sku-list-item .cont {
  padding: 15px;
}
.goods-storage-set .sku .sku-set-box .sku-list-item .cont .sku-value-list-item {
  position: relative;
  display: inline-block;
  min-width: 120px;
  border: 1px solid #f0f0f0;
  padding: 8px 12px;
  border-radius: 5px;
  margin-right: 15px;
  text-align: center;
}
.goods-storage-set .sku .sku-set-box .sku-list-item .cont .sku-value-list-item .anticon {
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: none;
}
.goods-storage-set .sku .sku-set-box .sku-list-item .cont .sku-value-list-item .anticon:hover {
  color: rgba(0, 0, 0, 0.4);
}
.goods-storage-set .sku .sku-set-box .sku-list-item .cont .sku-value-list-item:hover .anticon {
  display: block;
}
.goods-storage-set .sku .sku-set-box .foot {
  background-color: #f8f8f8;
  padding: 8px 15px;
}
.goods-storage-set .ant-input-number {
  width: 100%;
}
.skuChildPop .ant-popover-message-title {
  padding: 0 !important;
}
.modal-createshop {
  background-color: #fff;
  padding-bottom: 0;
}
.modal-createshop .ant-modal-content {
  background-color: transparent;
  box-shadow: none;
}
.modal-createshop .ant-modal-content .title {
  margin-bottom: 30px;
}
.modal-createshop .ant-modal-content .title h1 {
  font-size: 32px;
}
